<template>
  <div class="calendar-box">
    <div class="calendar-wrapper">
      <div class="calendar-toolbar">
        <div class="prev" @click="prevMonth"><DoubleLeftOutlined /></div>
        <div class="current" @click="current">{{ currentDateStr }}</div>
        <div class="next" @click="nextMonth"><DoubleRightOutlined /></div>
      </div>

      <div class="calendar-week">
        <div
          class="week-item calendarBorder"
          v-for="item of weekList"
          :key="item"
        >
          {{ item }}
        </div>
      </div>
      <div class="calendar-inner">
        <div
          class="calendar-item calendarBorder"
          v-for="(item, index) of calendarList"
          :key="index"
          :class="{
            highlight: item.highlight,
            'calendar-item-hover': choosable && !item.disable,
            'calendar-item-disabled': item.disable,
            'calendar-item-checked':
              choosable && dayChecked && dayChecked.value == item.value,
          }"
          @click="handleClickDay(item)"
        >
          {{ item.date }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, computed, watch } from "vue";

export default defineComponent({
  name: "Calendar",
  props: {
    choosable: {
      type: Boolean,
      default: true,
    },
    highlightDates: {
      type: Array,
      default: [],
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      showYearMonth: {}, // 显示的年月
      calendarList: [], // 用于遍历显示
      shareDate: new Date(), // 享元模式，用来做 日期数据转换 优化
      dayChecked: {}, // 当前选择的天
      weekList: ["一", "二", "三", "四", "五", "六", "日"], // 周
    });

    const currentDateStr = computed(() => {
      let { year, month } = state.showYearMonth;
      return `${year}年${pad(month + 1)}月`;
    });

    //#region 计算日历数据
    // 初始化数据
    function initDataFun() {
      // 初始化当前时间
      setCurrentYearMonth(); // 设置日历显示的日期（年-月）
      createCalendar(); // 创建当前月对应日历的日期数据
      getCurrentDay(); // 获取今天
    }
    initDataFun();
    // 点击当前月
    function current() {
      initDataFun();
      emit("monthChange", {
        type: "curr",
        year: state.showYearMonth.year,
        month: pad(state.showYearMonth.month + 1),
      });
    }
    // 设置日历显示的日期（年-月）
    function setCurrentYearMonth(d = new Date()) {
      let year = d.getFullYear();
      let month = d.getMonth();
      let date = d.getDate();
      state.showYearMonth = {
        year,
        month,
        date,
      };
    }
    function getCurrentDay(d = new Date()) {
      let year = d.getFullYear();
      let month = d.getMonth();
      let date = d.getDate();
      state.dayChecked = {
        year,
        month,
        date,
        value: stringify(year, month, date),
        disable: false,
      };
    }
    watch(
      () => props.highlightDates,
      () => {
        createCalendar();
      }
    );
    // 创建当前月对应日历的日期数据
    function createCalendar() {
      // 一天有多少毫秒
      const oneDayMS = 24 * 60 * 60 * 1000;
      let list = [];
      let { year, month } = state.showYearMonth;

      // #region
      // ---------------仅仅只算某月的天---------------
      //   // 当前月，第一天和最后一天的毫秒数
      //   let begin = new Date(year, month, 1).getTime();
      //   let end = new Date(year, month + 1, 0).getTime();

      // ---------------计算某月前后需要填补的天---------------
      // 当前月份第一天是星期几, 0-6
      let firstDay = getFirstDayByMonths(year, month);
      // 填充多少天，因为我将星期日放到最后了，所以需要另外调整下
      let prefixDaysLen = firstDay === 0 ? 6 : firstDay - 1;
      // 向前移动之后的毫秒数
      let begin = new Date(year, month, 1).getTime() - oneDayMS * prefixDaysLen;
      // 当前月份最后一天是星期几, 0-6
      let lastDay = getLastDayByMonth(year, month);
      // 填充多少天，因为我将星期日放到最后了，所以需要另外调整下
      let suffixDaysLen = lastDay === 0 ? 0 : 7 - lastDay;
      // 向后移动之后的毫秒数
      let end =
        new Date(year, month + 1, 0).getTime() + oneDayMS * suffixDaysLen;
      // // 计算左侧时间段的循环数
      // let rowNum = Math.ceil((end - begin) / oneDayMS / 7);
      // let newPeriod = [];
      // for (let i = 0; i < rowNum; i++) {
      //   newPeriod.push({});
      // }
      // #endregion

      // 填充天
      while (begin <= end) {
        // 享元模式，避免重复 new Date
        state.shareDate.setTime(begin);
        let year = state.shareDate.getFullYear();
        let curMonth = state.shareDate.getMonth();
        let date = state.shareDate.getDate();
        let dayValue = stringify(year, curMonth, date);
        list.push({
          year: year,
          month: curMonth + 1, // 月是从0开始的
          date: date,
          value: dayValue,
          disable: curMonth !== month,
          highlight: props.highlightDates.includes(dayValue),
        });
        begin += oneDayMS;
      }
      state.calendarList = list;
    }
    // 格式化时间
    function stringify(year, month, date) {
      let str = [year, pad(month + 1), pad(date)].join("-");
      return str;
    }
    // 对小于 10 的数字，前面补 0
    function pad(str) {
      return str < 10 ? `0${str}` : str;
    }
    // 点击上一月
    function prevMonth() {
      state.showYearMonth.month--;
      recalculateYearMonth(); // 因为 month的变化 会超出 0-11 的范围， 所以需要重新计算
      createCalendar(); // 创建当前月对应日历的日期数据
      emit("monthChange", {
        type: "prev",
        year: state.showYearMonth.year,
        month: pad(state.showYearMonth.month + 1),
      });
    }
    // 点击下一月
    function nextMonth() {
      state.showYearMonth.month++;
      recalculateYearMonth(); // 因为 month的变化 会超出 0-11 的范围， 所以需要重新计算
      createCalendar(); // 创建当前月对应日历的日期数据
      emit("monthChange", {
        type: "next",
        year: state.showYearMonth.year,
        month: pad(state.showYearMonth.month + 1),
      });
    }
    // 重算：显示的某年某月
    function recalculateYearMonth() {
      let { year, month, date } = state.showYearMonth;

      let maxDate = getDaysByMonth(year, month);
      // 预防其他月跳转到2月，2月最多只有29天，没有30-31
      date = Math.min(maxDate, date);

      let instance = new Date(year, month, date);
      setCurrentYearMonth(instance);
    }
    // 判断当前月有多少天
    function getDaysByMonth(year, month) {
      return new Date(year, month + 1, 0).getDate();
    }
    // 当前月的第一天是星期几
    function getFirstDayByMonths(year, month) {
      return new Date(year, month, 1).getDay();
    }
    // 当前月的最后一天是星期几
    function getLastDayByMonth(year, month) {
      return new Date(year, month + 1, 0).getDay();
    }
    // #endregion 计算日历数据
    // 操作：点击了某天
    function handleClickDay(item) {
      if (!props.choosable || !item || item.disable) return;
      state.dayChecked = item;
      emit("dayChange", item);
    }

    return {
      ...toRefs(state),
      currentDateStr,
      current,
      prevMonth,
      nextMonth,
      handleClickDay,
    };
  },
});
</script>
<style lang="less" scoped>
@calendarWidth: 427px; // 90 * 7 + 7 * 1
.calendar-box {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  .calendar-wrapper {
    .calendar-toolbar {
      width: @calendarWidth;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 14px;
      .prev,
      .next,
      .current {
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: @color-theme;
        }
      }
    }
    .calendar-week {
      width: @calendarWidth;
      border-left: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      .week-item {
        font-size: 16px;
        width: 60px;
        height: 50px;
        border-top: 1px solid #eee;
      }
    }
    .calendar-inner {
      width: @calendarWidth;
      border-left: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      .calendar-item {
        width: 60px;
        height: 60px;
        font-size: 18px;
      }
      .calendar-item-hover {
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: @color-theme;
        }
      }
      .calendar-item-disabled {
        color: #c3c3c3;
        cursor: not-allowed;
      }
      .calendar-item-checked {
        color: #fff;
        background-color: @color-theme;
      }
    }
    .calendarBorder {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
    }
    .highlight {
      color: @color-theme;
      position: relative;
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--theme);
        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
  }
}
</style>
