<template>
  <a-modal
    wrapClassName="medal-modal"
    :width="600"
    :bodyStyle="{ padding: 0 }"
    :centered="true"
    :maskClosable="false"
    :footer="null"
    :visible="visible"
    @cancel="cancel"
  >
    <div class="medal" id="medalWrap">
      <div class="medal-head">
        <template v-if="wearData">
          <img
            class="medal-head-stars left-stars"
            src="../../../assets/image/stars.png"
            alt=""
          />
          <img
            class="medal-head-stars right-stars"
            src="../../../assets/image/stars.png"
            alt=""
          />
          <img
            class="medal-head-light"
            src="../../../assets/image/light.png"
            alt=""
          />
          <img
            class="medal-head-wearing"
            src="../../../assets/image/wearing.png"
            alt=""
          />
          <img
            class="medal-head-img"
            :src="wearData.medalTemplatePath"
            alt=""
          />
          <div class="medal-head-name">{{ wearData.medalName }}</div>
        </template>
        <template v-else>
          <img
            class="medal-head-empty"
            src="../../../assets/image/no_medal.png"
            alt=""
          />
          <div class="medal-head-name">{{ $t("IHaventWornMedalYet") }}</div>
        </template>
      </div>
      <div class="medal-list">
        <div class="medal-list-title">{{ $t("MedalWall") }}</div>
        <div class="medal-list-content">
          <div
            class="medal-list-item"
            :class="{ gray: item.getTime == 0 }"
            v-for="item in medalData"
            :key="item.id"
            @click="wearing($event, item)"
          >
            <div class="img">
              <img :src="item.medalTemplatePath" />
            </div>
            <div class="name">{{ item.medalName }}</div>
            <div class="source">
              {{ $t("LB_Doc_Source") }}：{{ item.resourceName }}
            </div>
            <div class="time">
              {{ $t("TimeOfAward") }}：{{
                dateFormat(item.getTime, "YYYY-MM-DD")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import { dateFormat } from "@/utils/tools.js";
import { medalList, medalWear } from "@/api/user";

export default defineComponent({
  props: {
    user: {
      type: Object,
      default: {},
    },
  },
  setup(_, { emit }) {
    const state = reactive({
      visible: false,
      onWearing: false,
      wearData: null,
      medalData: [],
    });

    medalList({
      page: 1,
      pageSize: 10000,
    }).then((res) => {
      if (res.data.WearData) {
        state.wearData = res.data.WearData;
      }
      state.medalData = res.data.list || [];
    });

    const wearing = (e, item) => {
      if (item.getTime == 0) return false;
      if (item.isWear == 1) return false;
      if (state.onWearing) return false;
      state.onWearing = true;
      medalWear({ id: item.resId }).then((res) => {
        if (res.ret == 0) {
          state.medalData.forEach((item2) => {
            item2.isWear = 0;
          });
          item.isWear = 1;
          emit("wear", item);
        }
      });
      let modal = document.getElementsByClassName("ant-modal")[0];
      let oldImg = document.getElementsByClassName("medal-head-img")[0];
      let newImg = document.createElement("img");
      newImg.src = item.medalTemplatePath;
      newImg.setAttribute("class", "move-img");
      newImg.style.left = e.clientX - modal.offsetLeft - 50 + "px";
      newImg.style.top = e.clientY - modal.offsetTop - 50 + "px";
      document.getElementById("medalWrap").appendChild(newImg);
      setTimeout(() => {
        newImg.remove();
        oldImg.src = item.medalTemplatePath;
        state.onWearing = false;
      }, 900);
    };

    const show = () => {
      state.visible = true;
    };

    const cancel = () => {
      state.visible = false;
    };

    return {
      dateFormat,
      ...toRefs(state),
      wearing,
      show,
      cancel,
    };
  },
});
</script>
<style lang="less" scoped>
.medal {
  position: relative;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes zoomin {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes narrowin {
    from {
      opacity: 0;
      transform: scale(10);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  &-head {
    height: 221px;
    width: 100%;
    padding: 0;
    background: linear-gradient(
      62deg,
      rgba(154, 149, 245, 1) 0%,
      rgba(91, 79, 255, 1) 100%
    );
    position: relative;
    &-stars {
      width: 92px;
      height: 92px;
      position: absolute;
      opacity: 0;
      animation: fadein 1s;
      animation-fill-mode: forwards;
      // animation-delay: 1s;
      &.left-stars {
        top: 18px;
        left: 108px;
      }
      &.right-stars {
        top: 86px;
        left: 427px;
      }
    }
    &-light {
      width: 176px;
      height: 176px;
      position: absolute;
      top: 22px;
      left: 212px;
      transform: scale(0);
      animation: zoomin 1s;
      animation-fill-mode: forwards;
      // animation-delay: 1s;
    }
    &-wearing {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 48px;
      left: 360px;
      opacity: 0;
      transform: scale(10);
      animation: narrowin 1s;
      animation-fill-mode: forwards;
      // animation-delay: 1s;
    }
    &-empty {
      width: 148px;
      height: 148px;
      position: absolute;
      top: 24px;
      left: 226px;
      opacity: 1;
      &.no {
        opacity: 0;
        animation: fadeout 0.5s;
        animation-fill-mode: forwards;
      }
    }
    &-img {
      width: 96px;
      height: 96px;
      position: absolute;
      top: 65px;
      left: 251px;
    }
    &-name {
      color: #fff;
      font-size: 16px;
      text-align: center;
      .mixinEllipsis(24px);
      position: absolute;
      bottom: 18px;
      left: 20px;
      right: 20px;
    }
  }
  &-list {
    height: 506px;
    overflow-y: auto;
    padding: 0 16px;
    &-title {
      text-align: center;
      font-size: 20px;
      line-height: 34px;
      padding: 12px 0;
    }
    &-content {
      .mixinFlex();
      flex-wrap: wrap;
    }
    &-item {
      text-align: center;
      box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
      padding: 12px;
      width: 176px;
      margin: 0 17px 20px 0;
      cursor: pointer;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .img {
        .mixinImgWrap(64px; 64px);
        margin: 0 auto 16px;
      }
      &.gray {
        cursor: default;
        .img {
          filter: grayscale(1);
        }
      }
      .name {
        font-size: 14px;
        font-weight: bold;
        .mixinEllipsis(20px);
        margin-bottom: 10px;
      }
      .source {
        font-size: 14px;
        .mixinEllipsis(40px, 2);
        line-height: 20px;
        color: #999;
        margin-bottom: 10px;
      }

      .time {
        font-size: 12px;
        .mixinEllipsis(20px);
        color: #999;
      }
    }
  }
  @keyframes moveimg {
    to {
      transform: scale(1);
      top: 65px;
      left: 251px;
    }
  }
  ::v-deep(.move-img) {
    width: 96px;
    height: 96px;
    position: absolute;
    transform: scale(0.1);
    animation: moveimg 1s;
    animation-fill-mode: forwards;
  }
}
</style>
<style lang="less">
.medal-modal {
  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #fff;
  }
}
</style>
